<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          My Interviews</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="primary"
            class="mr-2"
            :to="{ name: 'my-interviews-dashboard' }"
          >
            Calendar
          </b-button>
          <b-button
            variant="primary"
            :to="{ name: 'my-interviews-edit'}"
          >
            Edit
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col
        cols="10"
        offset="1"
      >
        <b-row class="mt-1">
          <b-col cols="6">
            <b-form-group
              label="Name"
              label-cols-md="4"
              class="text-right"
            >
              <span
                class="form-control text-left"
                v-text="interviewer.name"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Email Address"
              label-cols-md="4"
              class="text-right"
            >
              <span
                class="form-control text-left"
                v-text="interviewer.email"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Phone Number"
              label-cols-md="4"
              class="text-right"
            >
              <span
                class="form-control text-left"
                v-text="interviewer.phone"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Status"
              label-cols-md="4"
              class="text-right"
            >
              <span
                class="form-control text-left"
                v-text="interviewer.status"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr class="mt-0">
        <b-row>
          <b-col cols="12">
            <div class="d-flex ml-4">
              <h4 class="mb-2">
                Time Slots
              </h4>
            </div>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Start Date"
              label-cols-md="4"
              class="text-right"
            >
              <span
                class="form-control text-left"
                v-text="interviewer.start_date"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="End Date"
              label-cols-md="4"
              class="text-right"
            >
              <span
                class="form-control text-left"
                v-text="interviewer.end_date"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Timings From"
              label-cols-md="4"
              class="text-right"
            >
              <b-row>
                <b-col cols="5">
                  <span
                    class="form-control text-left"
                    v-text="interviewer.available_start_time"
                  />
                </b-col>
                <b-col cols="7">
                  <b-form-group
                    label="To"
                    label-cols-md="3"
                    class="text-right mb-0"
                  >
                    <span
                      class="form-control text-left"
                      v-text="interviewer.available_end_time"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Duration"
              label-cols-md="4"
              class="text-right"
            >
              <span
                class="form-control text-left"
                v-text="interviewer.duration"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Recurring"
              label-cols-md="4"
              class="text-right"
            >
              <span
                class="form-control text-left"
                v-text="interviewer.recurring"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-show="showWeeks"
            cols="6"
          >
            <b-form-group
              label="Week Days"
              label-cols-md="4"
              class="text-right"
            >
              <span
                class="form-control text-left"
                v-text="interviewer.week_days"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group
              label="Block Time From"
              label-cols-md="4"
              class="text-right"
            >
              <span
                class="form-control text-left"
                v-text="interviewer.block_start_time"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="To"
              label-cols-md="4"
              class="text-right"
            >
              <span
                class="form-control text-left"
                v-text="interviewer.block_end_time"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Weekends"
              label-cols-md="4"
              label-class="text-right"
            >
              <span class="form-control field-height-auto">
                <span
                  v-for="(weekend, index) in interviewer.weekends"
                  :key="index"
                  :value="index"
                >
                  <b-badge
                    title="name"
                    variant="light-primary"
                    class="line-height-inherit my-25 mr-1"
                  >
                    <span>{{ weekend }}</span>
                  </b-badge>
                </span>
              </span>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Holidays"
              label-cols-md="4"
              label-class="text-right"
            >
              <span class="form-control field-height-auto">
                <span
                  v-for="(holiday, index) in interviewer.holidays"
                  :key="index"
                  :value="index"
                >
                  <b-badge
                    title="name"
                    variant="light-primary"
                    class="line-height-inherit my-25 mr-1"
                  >
                    <span>{{ holiday }}</span>
                  </b-badge>
                </span>
              </span>
            </b-form-group>
          </b-col>
        </b-row>

        <hr class="mt-0">
        <b-row>
          <b-col cols="12">
            <div class="d-flex ml-4">
              <h4 class="mb-2">
                Communication Mode Details
              </h4>
            </div>
          </b-col>
        </b-row>
        <b-row
          v-for="(communicationInfo, index) in interviewer.communications"
          :key="index"
          :value="index"
          class="mt-1"
        >
          <b-col cols="6">
            <b-form-group
              label="Type"
              label-cols-md="4"
              class="text-right"
            >
              <span
                class="form-control text-left"
                v-text="communicationInfo.type"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Value"
              label-cols-md="4"
              class="text-right"
            >
              <span
                class="form-control text-left"
                v-text="communicationInfo.value"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div
          v-if="interviewer.communications.length == 0"
          class="text-center"
        >
          <p> No Communication Mode Details Available </p>
        </div>

        <hr class="mt-0">
        <div class="d-flex mt-2">
          <h4 class="mb-0">
            Interviewer Skills
          </h4>
        </div>
        <div class="d-flex mt-2">
          <h5 class="mb-2 ml-4">
            Main Areas
          </h5>
        </div>
        <b-row>
          <b-col
            cols="11"
            offset="1"
          >
            <b-row>
              <b-col
                v-for="(skill, index) in interviewer.skills"
                :key="index"
                :value="index"
                cols="12"
                md="3"
                class="col-xxl-2 mb-1"
              >
                <b-form-checkbox
                  :checked="index"
                  :value="index"
                  disabled
                  class="custom-control-primary"
                >
                  {{ skill }}
                </b-form-checkbox>
              </b-col>
            </b-row>
            <div
              v-if="interviewer.skills.length == 0"
              class="text-center"
            >
              <p> No Skills Available </p>
            </div>
            <hr>
          </b-col>
        </b-row>
        <div class="d-flex mt-0">
          <h5 class="mt-2 ml-4">
            Vacation Dates
          </h5>
        </div>
        <b-row
          v-for="(vacationInfo, index) in interviewer.vacations"
          :key="index"
          :value="index"
          class="mt-1"
        >
          <b-col cols="6">
            <b-form-group
              label="From"
              label-cols-md="4"
              class="text-right"
            >
              <span
                class="form-control text-left"
                v-text="vacationInfo.vacation_start_date_text"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="To"
              label-cols-md="4"
              class="text-right"
            >
              <span
                class="form-control text-left"
                v-text="vacationInfo.vacation_end_date_text"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-show="!vacationInfo.is_all_day"
            cols="6"
          >
            <b-form-group
              label="Duration From"
              label-cols-md="4"
              class="text-right"
            >
              <span
                class="form-control text-left"
                v-text="vacationInfo.vacation_start_time"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-show="!vacationInfo.is_all_day"
            cols="6"
          >
            <b-form-group
              label="Duration To"
              label-cols-md="4"
              class="text-right"
            >
              <span
                class="form-control text-left"
                v-text="vacationInfo.vacation_end_time"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="All Day"
              label-cols-md="4"
              class="text-right"
            >
              <span
                class="form-control text-left"
                v-text="vacationInfo.is_all_day ? 'Yes' : 'No'"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="11"
            offset="1"
          >
            <hr>
          </b-col>
        </b-row>
        <div
          v-if="interviewer.vacations.length == 0"
          class="text-center"
        >
          <p> No Vacation Dates Available </p>
        </div>

      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BButton, BBadge, BFormCheckbox,
} from 'bootstrap-vue'
// import Constants from '@/js/constant'
import Interviewer from '@/js/interviewers'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BBadge,
    BFormCheckbox,
  },
  directives: {
  },
  data() {
    return {
      recurring: null,
      interviewer: {},
    }
  },
  mounted() {
  },
  created() {
    Interviewer.getInterviewerById(this, this.$store.state.profile.interviewer_id)
  },
  destroyed() {
  },
  methods: {
    setInterviewer(interviewer) {
      this.interviewer = interviewer
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/pages/app-chat-list.scss";
</style>
